//ReplyBar.js

import React, { useState, useEffect, useMemo } from "react";
import "./ReplyBar.css";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { Remarkable } from "remarkable";
import hljs from "highlight.js";
import { linkify } from "remarkable/linkify";

// 创建Remarkable实例时使用linkify插件
const md = new Remarkable({
  html: true,
  xhtmlOut: false,
  breaks: true,
  langPrefix: "language-",
  linkify: true,
  typographer: true,
  highlight: function (str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return hljs.highlight(lang, str).value;
      } catch (__) {}
    }
    return ""; // 使用外部默认的转义
  },
}).use(linkify);

const formatMessage = (text) => {
  // 分割消息文本为代码块和其他文本
  const regex = /```(\w*)\n([\s\S]*?)```/g;
  let lastIndex = 0;
  const parts = [];

  text.replace(regex, (match, language, code, index) => {
    // 添加非代码文本
    const nonCodeText = text.slice(lastIndex, index);
    if (nonCodeText) {
      parts.push(<div key={lastIndex} dangerouslySetInnerHTML={{ __html: md.render(nonCodeText) }} />);
    }

    // 添加代码块

    // Check if the code block is less than 30 characters
    if (code.trim().length < 30) {
      parts.push(<div key={index} dangerouslySetInnerHTML={{ __html: md.render(code) }} />);
    } else {
      parts.push(<CodeBlock key={index} code={code.trim()} language={language.trim()} />);
    }

    lastIndex = index + match.length;
  });

  // 添加最后一个代码块后的文本
  const finalText = text.slice(lastIndex);
  if (finalText) {
    parts.push(<div key={lastIndex + 1} dangerouslySetInnerHTML={{ __html: md.render(finalText) }} />);
  }

  return parts;
};

const CodeBlock = ({ code, language }) => {
  const [copied, setCopied] = useState(false);
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(code);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  return (
    <div className="code-block">
      <pre>
        <code>{code}</code>
      </pre>
      <button onClick={handleCopy} className={`copy-button ${copied ? "copied" : ""}`}>
        {copied ? "Copied" : "Copy"}
      </button>
    </div>
  );
};

const shouldShowCopyButton = (sender, text) => {
  return !(sender === "divider" || sender === "error" || text === "AI is taking longer than expected. Please try resending your message.");
};

const MemoizedMessageComponent = React.memo(({ sender, text }) => {
  const contentParts = formatMessage(text); // 直接调用formatMessage
  return (
    <div className={`message ${sender}`}>
      <strong>{sender === "user" ? "You: " : "AI: "}</strong>
      {contentParts.map((part) => part)}
    </div>
  );
});

const ReplyBar = ({ messages, uploadedFiles, formatMessage }) => {
  const [copiedIndex, setCopiedIndex] = useState(null);

  const memoizedMessages = useMemo(
    () =>
      messages.map((msg, index) => (
        <MemoizedMessageComponent
          key={index}
          sender={msg.sender}
          text={msg.text}
          copiedIndex={copiedIndex}
          setCopiedIndex={setCopiedIndex}
          index={index}
        />
      )),
    [messages, copiedIndex]
  );

  return <div className="reply-bar">{memoizedMessages}</div>;
};

export default ReplyBar;

// useFileHandler.js
import { useState } from "react";
export const useFileHandler = (initialFiles = []) => {
  const [uploadedFiles, setUploadedFiles] = useState(initialFiles);
  const handleFilesSelected = async (files, allowedTypes = ["txt", "js", "html", "css", "json"]) => {
    const filteredFiles = Array.from(files).filter((file) => allowedTypes.includes(file.name.split(".").pop().toLowerCase()));
    const fileContents = await Promise.all(filteredFiles.map(readFileContent));
    const newFiles = fileContents.map((content, index) => ({ name: filteredFiles[index].name, content }));
    setUploadedFiles((prev) => [...prev, ...newFiles.filter((file) => !prev.some((f) => f.name === file.name))]);
  };
  const removeUploadedFile = (fileName) => {
    setUploadedFiles(uploadedFiles.filter((file) => file.name !== fileName));
    // 重置文件上传 input 的值
    const fileInput = document.getElementById("file-upload");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const readFileContent = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(`⁂【${e.target.result}】⁂`);
      reader.onerror = (e) => reject(e);
      reader.readAsText(file);
    });
  };

  const clearUploadedFiles = () => {
    setUploadedFiles([]);
    // 重置文件上传 input 的值
    const fileInput = document.getElementById("file-upload");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return { uploadedFiles, handleFilesSelected, removeUploadedFile, clearUploadedFiles };
};
// InputBar.js
import React, { useState, useRef, useCallback } from "react";
import "./InputBar.css";
const InputBar = ({ onSend, handleFilesSelected, setLastUserInput, sendMessage }) => {
  const [input, setInput] = useState("");
  const textareaRef = useRef(null);
  // Debounce function
  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this,
        args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };
  const adjustTextareaHeight = useCallback(() => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    const maxHeight = window.innerHeight * 0.6;
    textarea.style.height = `${Math.min(textarea.scrollHeight, maxHeight)}px`;
  }, [textareaRef]);
  const debouncedAdjustTextareaHeight = useCallback(debounce(adjustTextareaHeight, 200), [adjustTextareaHeight]);
  const resetTextareaHeight = () => {
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
  };
  const handleSend = () => {
    if (input.trim()) {
      setLastUserInput(input.replace(/\n{2,}/g, "\n"));
      sendMessage(input.replace(/\n{2,}/g, "\n"));
      setInput("");
      resetTextareaHeight();
    }
  };
  return (
    <div className="input-bar">
      <textarea
        ref={textareaRef}
        value={input}
        onChange={(e) => {
          setInput(e.target.value);
          debouncedAdjustTextareaHeight();
        }}
        placeholder="Type your message here..."
      />
      <button onClick={handleSend} className="control-button">
        Send
      </button>
    </div>
  );
};
export default InputBar;

//AiService.js

export const fetchAiResponse = async (userInput, userId, apiKey, isContinuous, apiType) => {
  const promptData = {
    userId: userId,
    clearHistory: false,
    isContinuous: isContinuous,
    apiType: apiType,
    messages: [{ role: "user", content: userInput }],
  };

  const aiApiUrl = process.env.REACT_APP_AI_API_URL;

  console.log("Sending request to AI API with data:", promptData);

  try {
    const response = await fetch(aiApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey,
      },
      body: JSON.stringify(promptData),
    });

    if (response.ok) {
      let responseData;
      try {
        responseData = await response.json();
        // 使用新的响应格式
        if (responseData.content && typeof responseData.content === "string") {
          return responseData.content;
        } else {
          console.error("Response content is not a string or undefined:", responseData);
          return "Response format is unexpected. Please check the server response.";
        }
      } catch (jsonError) {
        throw new Error(`Error parsing JSON response: ${jsonError.message}`);
      }
    } else {
      let errorText;
      try {
        errorText = await response.text();
      } catch (textError) {
        throw new Error(`Error parsing error response text: ${textError.message}`);
      }
      throw new Error(`Network response not ok: ${response.status} ${response.statusText}, Error text: ${errorText}`);
    }
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
    throw error;
  }
};

export const clearHistory = async (userId, apiKey) => {
  const aiApiUrl = process.env.REACT_APP_AI_API_URL;

  try {
    const response = await fetch(aiApiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey,
      },
      body: JSON.stringify({ userId: userId, clearHistory: true }),
    });

    if (!response.ok) {
      throw new Error(`Network response not ok: ${response.status} ${response.statusText}`);
    }

    let responseBody = await response.json();
    if (responseBody.status === "success") {
      return responseBody;
    } else {
      throw new Error(`Error from server: ${responseBody.message}`);
    }
  } catch (error) {
    console.error("Error clearing history:", error);
    throw error;
  }
};

// LoginForm.js
import React, { useState, useEffect } from "react";
import "./LoginForm.css";

const LoginForm = ({ currentUserId, currentApiKey, apiType, onUserIdChange, onApiKeyChange, onApiTypeChange, setShowLogin }) => {
  const [newUserId, setNewUserId] = useState(currentUserId);
  const [newApiKey, setNewApiKey] = useState(currentApiKey);
  const [newApiType, setNewApiType] = useState(apiType);

  const [displayApiKey, setDisplayApiKey] = useState("".padStart(currentApiKey.length, "*"));
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    // 当 currentApiKey 改变时，设置 displayApiKey 为脱敏状态
    setDisplayApiKey("".padStart(currentApiKey.length, "*"));
  }, [currentApiKey]);

  const handleApiKeyChange = (e) => {
    const newApiKey = e.target.value;
    setNewApiKey(newApiKey);
    setDisplayApiKey(newApiKey);

    // Clear any existing timer
    if (timer) {
      clearTimeout(timer);
    }

    // Set a new timer
    const newTimer = setTimeout(() => {
      setDisplayApiKey("".padStart(newApiKey.length, "*"));
    }, 3000);
    setTimer(newTimer);
  };

  const handleLogin = () => {
    onUserIdChange(newUserId);
    onApiKeyChange(newApiKey);
    onApiTypeChange(newApiType); // 更新 apiType
    setShowLogin(false);
  };

  return (
    <div className="login-form">
      <input type="text" placeholder="Enter new user ID" value={newUserId} onChange={(e) => setNewUserId(e.target.value)} />
      <input type="text" placeholder="Enter API Key" value={newApiKey} onChange={(e) => setNewApiKey(e.target.value)} />
      <select value={newApiType} onChange={(e) => setNewApiType(e.target.value)}>
        <option value="claude">Claude</option>
        <option value="xqtd520qidong">XQTD520 Qidong</option>
      </select>
      <button onClick={handleLogin}>Save</button>
    </div>
  );
};

export default LoginForm;

// App.js
import React, { useState, useEffect, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Loader.css";
import TitleBar from "./components/TitleBar";
import ReplyBar from "./components/ReplyBar";
import InputBar from "./components/InputBar";
import { fetchAiResponse, clearHistory } from "./api/AiService";
import { saveAs } from "file-saver";
import "./App.css";
import { useFileHandler } from "./hooks/useFileHandler";
import { Remarkable } from "remarkable";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import { linkify } from "remarkable/linkify";

const md = new Remarkable({
  html: true,
  xhtmlOut: false,
  breaks: true,
  langPrefix: "language-",
  linkify: true,
  typographer: true,
  highlight: function (str, lang) {
    if (lang && hljs.getLanguage(lang)) {
      try {
        return hljs.highlight(lang, str).value;
      } catch (__) {}
    }
    return ""; // use external default escaping
  },
}).use(linkify);

const USER_ID_KEY = "user-id";
// Utility function to generate userId
const generateUserId = () => {
  const userLang = navigator.language;
  const randomDigits = Math.floor(1000 + Math.random() * 9000);
  return `web-${userLang}-${randomDigits}`;
};

function App() {
  const [userId, setUserId] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [apiType, setApiType] = useState("claude"); // 添加 apiType 状态
  const [messages, setMessages] = useState([{ text: "Hello, you can ask me about anything.", sender: "ai" }]);
  const [showLoader, setShowLoader] = useState(false);
  const [lastUserInput, setLastUserInput] = useState("");
  const [showRetry, setShowRetry] = useState(false);
  const [isContinuous, setIsContinuous] = useState(true);
  const responseTimeout = 30000;

  useEffect(() => {
    const storedUserData = localStorage.getItem("user-data");
    if (storedUserData) {
      const parsedData = JSON.parse(storedUserData);
      setUserId(parsedData.userId || generateUserId());
      setApiKey(parsedData.apiKey || "");
      setApiType(parsedData.apiType || "claude"); // 添加这一行以恢复 apiType
    } else {
      const newUserId = generateUserId();
      localStorage.setItem("user-data", JSON.stringify({ userId: newUserId, apiKey: "", apiType: "claude" }));
    }
  }, []);

  const handleUserIdChange = (newUserId) => {
    setUserId(newUserId);
    localStorage.setItem("user-data", JSON.stringify({ userId: newUserId, apiKey }));
  };

  const handleApiKeyChange = (newApiKey) => {
    setApiKey(newApiKey);
    localStorage.setItem("user-data", JSON.stringify({ userId, apiKey: newApiKey }));
  };

  const handleApiTypeChange = (newApiType) => {
    setApiType(newApiType);
    const userData = JSON.parse(localStorage.getItem("user-data") || "{}");
    localStorage.setItem("user-data", JSON.stringify({ ...userData, apiType: newApiType }));
  };

  const scrollToBottom = useCallback(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  const { uploadedFiles, handleFilesSelected, removeUploadedFile, clearUploadedFiles } = useFileHandler();

  const sendMessage = async (userInput) => {
    setShowLoader(true);
    setShowRetry(false);
    setMessages((msgs) => [...msgs, { text: userInput, sender: "user" }]);

    const filesContent = uploadedFiles.map((file) => `${file.name}: ${file.content}`).join("\n");
    const combinedContent = (filesContent ? "\n" + filesContent : "") + userInput;

    const timeoutId = setTimeout(() => {
      setShowLoader(false);
      setMessages((msgs) => [...msgs, { text: "AI is taking longer than expected. Please try resending your message.", sender: "error" }]);
    }, responseTimeout);

    try {
      const responseText = await fetchAiResponse(combinedContent, userId, apiKey, isContinuous, apiType);

      setShowLoader(false);
      clearTimeout(timeoutId);
      setMessages((msgs) => [...msgs, { text: responseText, sender: "ai" }]);
      clearUploadedFiles();
    } catch (error) {
      setShowLoader(false);
      setMessages((msgs) => [...msgs, { text: `Error: ${error.message}`, sender: "error" }]);
      setShowRetry(true);
    }
  };

  const handleRetry = () => {
    if (lastUserInput) {
      sendMessage(lastUserInput);
      setShowRetry(false);
    }
  };

  const handleSend = (userInput, resetInputCallback) => {
    setLastUserInput(userInput.replace(/\n{2,}/g, "\n"));
    sendMessage(userInput.replace(/\n{2,}/g, "\n"));
    if (resetInputCallback && typeof resetInputCallback === "function") {
      resetInputCallback();
    }
  };

  const addDivider = () => {
    setMessages((msgs) => [...msgs, { text: "---- New Chat ----", sender: "divider" }]);
  };

  const handleDownload = () => {
    const chatHistory = messages.map((msg) => `${msg.sender}: ${msg.text}`).join("\n");
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0].replace(/-/g, "");
    const filename = `Trustavo-AI-Chat-${formattedDate}.txt`;
    const blob = new Blob([chatHistory], { type: "text/plain;charset=utf-8" });
    saveAs(blob, filename);
  };

  const renderLoader = () => (
    <div className="loader-container" style={{ display: showLoader ? "flex" : "none" }}>
      <div className="loader">
        <span className="loader-text">Thinking</span>
        <span className="load"></span>
      </div>
    </div>
  );

  const handleClear = async () => {
    try {
      const response = await clearHistory(userId, apiKey);
      if (response.status === "success") {
        setMessages([{ text: "Hello, you can ask me about anything.", sender: "ai" }]);
        console.log("History cleared successfully");
      } else {
        throw new Error(`Failed to clear history: ${response.message || "An unknown error occurred"}`);
      }
    } catch (error) {
      setMessages((msgs) => [...msgs, { text: `Error: ${error.message}`, sender: "error" }]);
      console.error("Error clearing history:", error);
    }
  };

  const handleContinuousChange = (e) => {
    setIsContinuous(e.target.checked);
  };
  return (
    <div className="App">
      <div className="container">
        <TitleBar
          userId={userId}
          apiKey={apiKey}
          apiType={apiType} // 确保传递 apiType
          onUserIdChange={handleUserIdChange}
          onApiKeyChange={handleApiKeyChange}
          onApiTypeChange={handleApiTypeChange} // 传递 handleApiTypeChange
        />

        <div className="row">
          <ReplyBar messages={messages} uploadedFiles={uploadedFiles} />
        </div>
        <div className="button-container">
          {showRetry && (
            <button onClick={handleRetry} className="control-button">
              Retry
            </button>
          )}

          {renderLoader()}
          <label htmlFor="file-upload" className="control-button upload-button file-upload-label">
            Upload
            <input
              id="file-upload"
              type="file"
              multiple
              accept=".txt, .js, .html, .css, .json"
              onChange={(e) => handleFilesSelected(e.target.files)}
              style={{ display: "none" }}
            />
          </label>

          <button onClick={handleClear} className="control-button">
            Clear
          </button>

          <label className="switch">
            <input type="checkbox" checked={isContinuous} onChange={handleContinuousChange} />
            <span className="slider"></span>
            <span>Continuous</span>
          </label>

          <button onClick={handleDownload} className="control-button">
            Download
          </button>
        </div>
        <div className="uploaded-files-list">
          {uploadedFiles.map((file) => (
            <div key={file.name} className="uploaded-file">
              {file.name}
              <button onClick={() => removeUploadedFile(file.name)} className="remove-file-button">
                X
              </button>
            </div>
          ))}
        </div>
        <div className="row">
          <InputBar onSend={handleSend} sendMessage={sendMessage} handleFilesSelected={handleFilesSelected} setLastUserInput={setLastUserInput} />
        </div>
      </div>
    </div>
  );
}

export default App;
